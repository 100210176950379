export default {
  "accountHistory": {
    "error": {
      "INVALID_ACCOUNT_HISTORY_TIME_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The time frame is too long. Please use a maximum of three months."])},
      "INVALID_ORDER_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is not a valid order ID"])},
      "NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred! Please try reloading the page."])},
      "NetworkError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred! Please try reloading the page. Should the problem persists, please contact our support."])}
    }
  },
  "app": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One Trading"])},
    "version": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Current version ", _interpolate(_named("version"))])}
  },
  "banners": {
    "user": {
      "WAITING_FOR_DOCUMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please submit the requested business documents to proceed with the registration of your account."])},
      "WAITING_FOR_DOCUMENTS_APPROVAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are currently in the process of verifying your submitted documents and will notify you via email once we have finished."])}
    }
  },
  "fundamentals": {
    "INSUFFICIENT_FUNDS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient funds"])},
    "accountRatio": {
      "status": {
        "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Healthy"])},
        "DERISK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derisk"])},
        "LIQUIDATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidation"])}
      }
    },
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["active"])},
    "addFunds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add funds"])},
    "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "authCodeErrors": {
      "AUTH_CODE_ALREADY_USED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This authentication code has been used already."])},
      "AUTH_CODE_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorization code is invalid"])},
      "AUTH_CODE_MAX_FAILED_ATTEMPTS_REACHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have typed in an incorrect 2FA code 3 times in a row. Any action that requires 2FA has been frozen for the next 5 min."])},
      "AUTH_CODE_REJECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorization code is invalid"])},
      "AUTH_CODE_REQUIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorisation code required"])},
      "INVALID_AUTH_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorisation code is not valid"])}
    },
    "availableFunds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Available funds ", _interpolate(_list(0))])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "copy": {
      "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
      "message": {
        "field": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " was copied to the clipboard."])},
        "pid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PID was copied to the clipboard."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied to clipboard"])}
    },
    "currencies": {
      "BEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BEST"])},
      "BPD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["British pound"])},
      "BTC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitcoin"])},
      "CHF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swiss Franc"])},
      "EOS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EOS"])},
      "ETH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethereum"])},
      "EUR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Euro"])},
      "GBP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["British Pound"])},
      "LTC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litecoin"])},
      "MIOTA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IOTA"])},
      "PAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pantos"])},
      "REP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Augur"])},
      "TETH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Ether"])},
      "TRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkish Lira"])},
      "USD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["US dollar"])},
      "USDT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tether"])},
      "XLM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellar"])},
      "XRP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripple"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])}
    },
    "currenciesMessages": {
      "maintenanceReason": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("symbol")), " wallets are in maintenance and currently set to trade only (buy and sell). We are working hard to bring them back online as soon as possible."])},
      "unavailableReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet under maintenance due to an update. We are working hard to bring it back online as soon as possible."])}
    },
    "currenciesMetaInfos": {
      "ATOM": {
        "destinationTag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memo"])}
      },
      "EOS": {
        "destinationTag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memo"])}
      },
      "XEM": {
        "destinationTag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])}
      },
      "XLM": {
        "destinationTag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memo"])},
        "destinationTagId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memo ID"])},
        "insertDestinationTag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Memo (optional)"])},
        "insertDestinationTagId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Memo ID (optional)"])}
      },
      "XRP": {
        "destinationTag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination tag"])}
      }
    },
    "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
    "defaultError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong! Please try again later."])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "emptyValues": {
      "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–"])}
    },
    "error": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong! Please try again later."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])}
    },
    "errorModal": {
      "defaultText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try again, if the problem persist please contact support."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])}
    },
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inactive"])},
    "leverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverage"])},
    "locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locked"])},
    "lockedFunds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Locked funds ", _interpolate(_list(0))])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max"])},
    "mobileExpandedTableHeaderPostfix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
    "multiSelect": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nothing selected"])},
      "selectedNumber": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Selected: ", _interpolate(_named("value"))])}
    },
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
    "noMatchingOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No matching options"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "okay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okay"])},
    "orders": {
      "side": {
        "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
        "long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Long"])},
        "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
        "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short"])}
      },
      "status": {
        "CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])},
        "CLOSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelling..."])},
        "FAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
        "FILLED": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percent")), "% filled"])},
        "FILLED_CLOSED": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percent")), "% filled, cancelled"])},
        "FILLED_FULLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filled"])},
        "FILLED_REJECTED": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percent")), "% filled"])},
        "IN_MAINTENANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market maintenance"])},
        "OPEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
        "PROCESSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing"])},
        "REJECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])},
        "STOP_TRIGGERED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Triggered"])},
        "SUSPENDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market suspended"])}
      },
      "type": {
        "LIMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
        "LIMIT_SHORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit"])},
        "MARKET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
        "MARKET_SHORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market"])},
        "STOP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
        "STOP_SHORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop"])},
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
        "undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undefined"])}
      }
    },
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
    "paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste"])},
    "perpetual": {
      "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perp"])}
    },
    "pid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PID"])},
    "prefixes": {
      "orderId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XO"])},
      "subaccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xs"])},
      "tradeId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XT"])},
      "transactionIdCrypto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XC"])},
      "transactionIdFiat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XF"])}
    },
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
    "table": {
      "noRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no records to display yet"])}
    },
    "timePeriod": {
      "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1D"])},
      "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1H"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1M"])},
      "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1W"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1Y"])}
    },
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
    "toasts": {
      "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
      "failedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try later."])}
    },
    "trade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade"])},
    "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer"])},
    "transferMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer method"])},
    "transferType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer type"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "unavailableError": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whoops! Something went wrong! Please try again later."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oh no!"])}
    },
    "understood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Understood"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
    "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw"])}
  },
  "modals": {
    "selectTradeType": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use \"Swap\" for quick trades or \"Exchange\" for more advanced trading options."])},
      "options": {
        "instantTrade": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instantly swap over 100 assets"])}
        },
        "spot": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade on our exchange markets"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a trade type"])}
    }
  },
  "modules": {
    "ExchangeNavigationTabs": {
      "accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts"])},
      "futures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Futures"])},
      "markets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markets"])},
      "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])}
    },
    "account": {
      "competitions": {
        "headers": {
          "competition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competition"])},
          "competitionPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competition Period"])},
          "prizes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prizes"])}
        },
        "table": {
          "readMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])},
          "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competitions"])}
      },
      "partners": {
        "blockpit": {
          "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockpit is offering a real-time transaction monitoring and tax reporting platform for digital assets. Connect your exchanges and wallets via API to gain a clear overview of your realized profits, deductible losses, expenses, performance and more. Export a full report anytime, which is based on a audited legal framework specific for your country.\nSpecial events like BEST fee payments, airdrops, hardforks, transfers between wallets and many more are automatically classified for your convenience and regulatory safety."])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In most countries, the trade of cryptocurrencies has to be taxed. Blockpit offers a software to keep an overview of your taxes and trades in an optimised way.\nSince its foundation in 2017, Blockpit has been pursuing the goal of bringing the necessary transparency and trust to a blockchain-based financial market with software compliance solutions."])},
          "requestVoucher": {
            "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claim offer"])},
            "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voucher generation failed"])}
          }
        },
        "coinrule": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coinrule allows traders to automate their investment strategy.\nUse the sign up link to claim your discount."])},
          "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 month free + 12 months at a 50% discount"])}
        },
        "coupon": {
          "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon copied to clipboard."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon code"])}
        },
        "cryptohopper": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cryptohopper is the leading automated crypto trading bot; build your strategy with technical indicators and candle patterns in Cryptohopper's built-in strategy designer, use trailing stops, copy other traders, connect to TradingView, test your strategies by paper trading or backtest them. Cryptohopper also offers market making, exchange arbitrage and market arbitrage. Check your positions on the go by using the iOS or Android app and use Cryptohopper for your portfolio management!"])},
          "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20% discount on yearly subscriptions"])}
        },
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Below, you can find a list of One Trading promotions."])},
        "groups": {
          "portfolioManagement": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio management"])}
          },
          "promotions": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotions"])}
          },
          "tradingBots": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trading bots"])}
          }
        },
        "headers": {
          "bestOnExchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BEST holdings"])},
          "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
          "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
          "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer"])},
          "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
          "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
          "signUpLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up link"])}
        },
        "kryptview": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["One Trading partners with Kryptview to launch the One Trading x Kryptview Research Bounties! Score the participating tokens on the first Research-to-Earn platform to win a 0 trading fee privilege on One Trading and qualify for a giveaway of a total value of USDT 1000! During the Kryptview Beta, partner tokens will be highlighted on the platform for a certain amount of time under the \"Research Bounties\" section. Scoring those tokens or peer-reviewing other contributors’ scorings during the operation will grant you double the experience points! The promotion will run from ", _interpolate(_named("from")), " to ", _interpolate(_named("to")), " and the 0 fee level will be applied for 3 months starting ", _interpolate(_named("starting")), "."])}
        },
        "napbots": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NapBots is part of the Napoleon Group which is a French group of entities founded by former multi-billion asset managers at Tier 1 banks, who have designed, created and run high-performance trading bots for the past ten years.\nTheir value proposal is underpinned by long-standing, high-performing trading bots running on a variety of highly-liquid crypto assets.\n\nThe NapBots team is constantly developing and managing new trading bots that allow clients to subscribe and automate trading strategies directly for their exchange account."])},
          "discount": {
            "level0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 month free + 2 months at 63% discount"])}
          }
        },
        "table": {
          "blockpit": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitcoin Tax Software for Cryptocurrency Tax Return"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockpit"])},
            "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register now to get exclusive discounts as a One Trading user."])},
            "offerRegular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register now to get 10% off on your first tax year."])},
            "offerTemporary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register now to get 25% off your 2020 and 2021 tax licences."])}
          },
          "coinrule": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automated Strategies Crypto Framework"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coinrule"])},
            "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 month free + 12 months at a 50% discount"])}
          },
          "cryptohopper": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["World-class automated crypto trading bot"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cryptohopper"])},
            "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20% discount on yearly subscriptions"])}
          },
          "haasonline": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced crypto bot framework"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haasonline"])},
            "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 month free + 9 months at a 50% discount"])}
          },
          "kryptview": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community-driven crypto-scoring platform"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kryptview"])},
            "offer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Promotion from ", _interpolate(_named("from")), " to ", _interpolate(_named("to")), " to earn 0 trading fees for 3 months on One Trading"])}
          },
          "napbots": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automated crypto trading bots"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NapBots"])},
            "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 month free + 2 months at 63% discount"])}
          },
          "readMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotions"])}
      },
      "settings": {
        "biometric": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For added security, we recommend that you enable biometric authentication."])},
          "enableBiometrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable biometrics"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biometric authentication"])},
          "toasts": {
            "disabled": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biometric application lock is successfully disabled."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
            },
            "enabled": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biometric application lock is successfully enabled."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
            }
          }
        },
        "broker": {
          "currency": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency settings"])}
          },
          "language": {
            "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language settings"])}
          },
          "security": {
            "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security settings"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Settings"])}
        },
        "currency": {
          "defaultChangedMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We have changed your default currency to ", _interpolate(_named("currency")), ". You can choose a different currency in your settings."])},
          "defaultChangedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default currency changed"])}
        },
        "fees": {
          "currentLevel": {
            "kryptviewTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KryptView promotion applied"])},
            "maker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maker fee"])},
            "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taker fee"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current fee level"])}
          },
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["To get the most out of BEST you can use it to pay trading fees. Enable Pay trading fees with BEST to reduce your trading fee costs by ", _interpolate(_named("value")), "%."])},
          "enable": {
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable Pay trading fees with BEST"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay trading fees with BEST"])}
        },
        "marketOrdersSlippage": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Market order should be treated with caution as there is a risk of \"slippage\": the price at which the asset is bought or sold may be significantly less favourable than the current best ask price/best bid price in our order book. Slippage protection protects to some extent against such high-risk situations by ensuring that the price is within a specified percentage range of the current best ask (for purchases)/bid (for sales) price on the One Trading Platform. Note that this function will convert any market orders to Immediate or Cancel Limit Orders at the relevant amount, and may thus only execute partly and not fully. E.g., if you set the level to 15%, then a buy Market Order for one unit of a crypto-asset with the best ask price on our platform of EUR 100 will be converted to an Immediate or Cancel Limit Order at a price of EUR 115 for such unit."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max Slippage (%)"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market orders slippage protection"])}
        },
        "myOrders": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage your display setting for your orders here to fit your needs"])},
          "removeCancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove cancelled orders immediately"])},
          "removeFilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove filled orders immediately"])},
          "showAnimations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show animations"])},
          "showTimestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show timestamp instead of dynamic time"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My orders"])}
        },
        "navigation": {
          "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
          "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
          "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders"])},
          "rateLimits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate limits"])},
          "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security"])}
        },
        "navigationTab": {
          "beneficiary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linked accounts"])},
          "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])}
        },
        "notifications": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage your notifications here. For the events below, you will receive a notification every time one of these events is triggered. We recommend enabling all notifications in order to be fully aware of what is happening with your orders. But for some users, it could be useful to disable some of them."])},
          "order": {
            "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order cancelled"])},
            "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order created"])},
            "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order failed"])},
            "filled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order filled"])},
            "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order rejected"])},
            "triggered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order triggered"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In-app notifications"])}
        },
        "orderbook": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The order book offers two methods of presenting the current spread, defined as the difference between the best bid price and the best ask price. By default, the spread is depicted in the quote currency, providing a direct nominal value indication of the price variance within the market. Alternatively, you can toggle the spread display to show the spread in basis points (BPS). This option offers a granular percentage representation of the price difference in the base currency, facilitating direct comparisons across different markets."])},
          "spreadInBasePoints": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable spread in base points"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order book spread"])}
        },
        "orders": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the system detects a possible irrational action when an order is being placed, a confirmation dialogue will be displayed."])},
          "limit": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit order confirmations"])}
          },
          "market": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market order confirmations"])}
          },
          "stop": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop order confirmations"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order confirmations"])}
        },
        "rateLimit": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate limit warnings are mainly for users accessing One Trading via API. To ensure the security of One Trading a sensible rate limit has been applied for all users."])},
          "showCloseToHitting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show warnings when you are close to hitting the rate limit"])},
          "showHit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show warnings when you hit your rate limit"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate limit warnings"])}
        },
        "securityWarnings": {
          "twoFa": {
            "message": {
              "linkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enable two-factor authentication (2FA)"])},
              "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["In order to improve the security of your account we encourage you to ", _interpolate(_named("linkText"))])}
            }
          },
          "verify": {
            "message": {
              "linkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verify your account first"])},
              "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["In order to deposit fiat or crypto, you need to ", _interpolate(_named("linkText"))])}
            }
          }
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
        "toasts": {
          "updatePayInBest": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabling Pay trading fees with BEST failed."])}
          }
        }
      }
    },
    "accountBalance": {
      "dropdownTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated account value"])},
      "dropdownTitleMainAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main account"])},
      "hidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hidden"])},
      "links": {
        "balances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balances"])},
        "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit"])},
        "orderHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order history"])},
        "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])},
        "tradeHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade history"])},
        "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])},
        "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw"])}
      }
    },
    "accountHistory": {
      "error": {
        "retryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whoops, something went wrong!"])}
      },
      "export": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
        "fullHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full history"])},
        "modal": {
          "actions": {
            "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate export(s)"])}
          },
          "button": {
            "trade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download trade history"])},
            "tradeWait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait, we are preparing your trade history"])},
            "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download transaction history"])}
          },
          "dropdowns": {
            "base": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base"])}
            },
            "quote": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quote"])}
            },
            "selectAccount": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select account"])}
            },
            "selectCurrencies": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select currencies"])}
            }
          },
          "includeTrades": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export trades"])}
          },
          "includeTransactions": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export transactions"])}
          },
          "preparing": {
            "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
            "text": {
              "trade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are now preparing your trade history export. In a few moments, you will receive an email with a download link."])},
              "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are now preparing your transaction history export. In a few moments, you will receive an email with a download link."])}
            }
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export history"])}
        },
        "requests": {
          "errors": {
            "PAYLOAD_TOO_LARGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try requesting all records"])}
          }
        }
      },
      "fees": {
        "insufficientBest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient BEST"])},
        "partiallyInBest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trading fees partially paid with BEST"])}
      },
      "headers": {
        "accountHistoryTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order history"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
        "averagePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average price"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
        "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee"])},
        "filledAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filled amount"])},
        "lastUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last updated"])},
        "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market"])},
        "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order:"])},
        "orderId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order ID"])},
        "orderSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order summary"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
        "showAllFilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show cancelled and rejected"])},
        "side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Side"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "stopPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop price"])},
        "stopTriggered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop triggered"])},
        "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
        "totalFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total fee"])},
        "totalFeeInBest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BEST fee"])},
        "tradeId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade ID"])},
        "tradeNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of trades"])},
        "tradeSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade summary"])},
        "trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trades"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])}
      },
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that only the last 250 orders from the new F.A.S.T exchange version are displayed. Orders from the previous version can be retrieved using the Export function."])},
      "links": {
        "showAllTrades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all trades"])}
      },
      "messages": {
        "showMoreTrades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are more trades than can be displayed"])}
      },
      "orders": {
        "type": {
          "LIMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit order"])},
          "LIMIT_SHORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit"])},
          "MARKET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market order"])},
          "MARKET_SHORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market"])},
          "STOP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop order"])},
          "STOP_SHORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop"])}
        }
      },
      "tabs": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["open"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filled"])}
      },
      "toasts": {
        "showAllFilledLimit": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We only show cancelled and rejected orders from the past 6 months."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention"])}
        }
      },
      "transactions": {
        "quickFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick filter"])},
        "quickFilterOptions": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
          "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
          "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outgoing"])}
        }
      }
    },
    "accountOverview": {
      "header": {
        "assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assets"])},
        "availableMargin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available margin"])},
        "equity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equity"])},
        "maintenanceMargin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance margin"])},
        "marginLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Margin level"])},
        "marginRatio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Margin ratio"])},
        "positionValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position value"])},
        "realisedPnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realised PnL"])},
        "usedMargin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Used margin"])}
      }
    },
    "accounts": {
      "health": {
        "titles": {
          "total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Account total balance (", _interpolate(_named("symbol")), ")"])}
        }
      },
      "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " account"])},
      "typeTag": {
        "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Account"])},
        "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Account"])}
      }
    },
    "apiKeys": {
      "add": {
        "buttons": {
          "authorize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorise now"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create API key"])}
        },
        "form": {
          "apiErrorCodes": {
            "AUTH_CODE_ALREADY_USED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This authentication code has been used already."])},
            "AUTH_CODE_MAX_FAILED_ATTEMPTS_REACHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have typed in an incorrect 2FA code 3 times in a row. Any action that requires 2FA has been frozen for the next 5 min."])},
            "AUTH_CODE_REJECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorization code is invalid"])},
            "DEFAULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
            "INTERNAL_SERVER_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
            "INVALID_APIKEY_IPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid IP addresses were specified"])},
            "INVALID_APIKEY_SCOPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid permissions were specified"])},
            "INVALID_AUTH_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorisation code is not valid"])},
            "MAX_APIKEY_EXCEEDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum amount of API keys reached"])},
            "UNAUTHORIZED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorised request"])}
          },
          "labels": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API key name"])},
            "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
            "restrictions": {
              "hint": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Add trusted IP(s), separate multiple entries (max. ", _interpolate(_named("number")), ") with a comma"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use IP restriction (optional)"])},
              "placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Add trusted IP(s), separate multiple entries (max ", _interpolate(_named("number")), ") with a comma"])},
              "placeholderShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trusted IP(s)"])}
            }
          },
          "subaccountTransfers": {
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subaccount transfers are only available on the main account at the moment"])}
          },
          "validations": {
            "name": {
              "matchesRegex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alphanumeric characters, spaces, dash and underscore are allowed"])},
              "maxLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The name is too long"])},
              "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The name is required"])}
            },
            "permissions": {
              "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least one permission is required"])}
            },
            "restrictions": {
              "matchesRegex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One of the addresses is not a valid ipv4/ipv6 address"])},
              "noDuplicates": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Duplicate IP(s) ", _interpolate(_named("ip"))])}
            }
          },
          "withdrawal": {
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawals cannot be enabled for subaccounts"])}
          }
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New API key"])}
      },
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API"])},
      "buttons": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New API key"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
        "documentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation"])}
      },
      "confirm": {
        "buttons": {
          "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
          "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy and close"])}
        },
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your API key"])},
        "messages": {
          "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API key copied to clipboard"])},
          "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The link is invalid."])},
          "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the only time you will see this API key. Make sure you copy it and store it in a secure place."])}
        },
        "titles": {
          "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oh no!"])},
          "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here is your API key!"])}
        }
      },
      "created": {
        "buttons": {
          "okay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])}
        },
        "message": [
          (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We have sent you an email to ", _interpolate(_named("email")), "."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please go to your email inbox and confirm the API key creation. For security reasons the confirmation link will expire after 30 min."])},
          
        ],
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm email"])}
      },
      "delete": {
        "buttons": {
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, delete it"])}
        },
        "failed": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to delete API key."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])}
        },
        "message": [
          (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You are about to delete the following API key: ", _interpolate(_named("name"))])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete it?"])},
          
        ],
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete API key?"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API keys are for our advanced users who wish to connect third-party applications."])},
      "errorMessages": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try reloading the page. Should the problem persists, please contact our support."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error when fetching your API keys."])}
      },
      "headers": {
        "apiKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API key"])},
        "ipRestriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP restriction"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
      },
      "permissions": {
        "READ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read"])},
        "SUBACCOUNT_TRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subaccount transfers"])},
        "TRADE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade"])},
        "WITHDRAW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw"])}
      },
      "status": {
        "CONFIRMED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirmed"])},
        "UNCONFIRMED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unconfirmed"])}
      },
      "tabName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keys"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API key management"])},
      "values": {
        "hiddenKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*********"])},
        "unrestrictedKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unrestricted"])}
      }
    },
    "auth": {
      "biometricPromptOptions": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in to One Trading"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One Trading biometric log in"])},
        "useBackup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use backup"])},
        "usePin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use PIN"])}
      },
      "button": {
        "sensorBiometric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock with biometric"])},
        "useBiometric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use biometric"])},
        "useFaceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use face ID"])},
        "useFingerprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use fingerprint"])},
        "usePin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use your PIN instead"])}
      },
      "confirmModal": {
        "biometric": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to enable biometric authentication for easy access to the One Trading app?"])},
          "messageFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We failed to recognise your biometrics. Do you want to try again or use your PIN?"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable biometric authentication?"])},
          "titleFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication failed"])}
        },
        "face": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to enable FaceID authentication for easy access to the One Trading App?"])},
          "messageFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We failed to recognise your face ID. Do you want to try again or use PIN?"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable FaceID authentication?"])},
          "titleFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication failed"])}
        },
        "fingerprint": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to enable finger print authentication for easy access to One Trading app?"])},
          "messageFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We failed to recognise your fingerprint. Do you want to try again or use PIN?"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable finger print authentication?"])},
          "titleFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication failed"])}
        }
      },
      "loginBiometric": {
        "appIsLocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App is locked"])},
        "noBiometric": {
          "actionButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to security settings"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One Trading requires all our app users to have either PIN or biometric authentication enabled in order to use the app.\n\nPlease go to your phone settings and enable PIN or biometric authentication in order to proceed."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication"])},
          "userError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your authentication failed and the app is currently locked. Please check back in a little while."])}
        },
        "unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock"])},
        "unlockWithBiometricAuthentication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock with biometric authentication"])},
        "unlockWithDeviceCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock with device credentials"])},
        "unlockWithFaceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock with Face ID"])},
        "unlockWithFingerprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock with fingerprint"])},
        "unlockWithTouchId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock with Touch ID"])}
      },
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
      "notes": {
        "touchSensor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Touch the fingerprint sensor"])}
      },
      "subtitle": {
        "lastTry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have one attempt left before we lock the app for 5 minutes"])},
        "lastTryBeforeLogOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final attempt before you are logged out"])},
        "pinIncorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIN incorrect, please try again."])},
        "putInPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your PIN"])},
        "repeatPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please repeat your PIN."])},
        "setupPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For added security, please add a PIN to unlock the app."])}
      },
      "title": {
        "authRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication required"])},
        "loginBiometric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in using biometric"])},
        "loginFaceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in using face ID"])},
        "loginFingerprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in using fingerprint"])},
        "loginPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your PIN"])},
        "putInPinOrBiometric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your PIN or use biometric authentication."])},
        "setupPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set up PIN"])}
      }
    },
    "balances": {
      "header": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
        "coin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coin"])},
        "cryptoAndFiatValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value in BTC / EUR"])},
        "cryptoValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value in BTC"])},
        "fiatValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value in EUR"])},
        "locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locked in orders"])},
        "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratio"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total amount"])},
        "valueIn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Value in ", _interpolate(_named("symbol"))])},
        "valueInTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value is provided based on available market data and subject to change."])}
      },
      "hideBalances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide balances under 0.00001 BTC"])},
      "hideSmallBalances": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hide small balances (values < ", _interpolate(_named("number")), " ", _interpolate(_named("symbol")), ")"])},
      "links": {
        "subaccountBalances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all account balances"])}
      },
      "tabs": {
        "crypto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto"])},
        "fiat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiat"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balances"])}
    },
    "charting": {
      "resetColorScheme": {
        "button": {
          "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep"])},
          "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore"])}
        },
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have applied different colours, matching our new design, to your charts. You can keep them or restore your previously configured colour scheme."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New chart colours applied"])}
      },
      "restoreSavedCharts": {
        "banner": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved chart layouts couldn’t be loaded and newly added customisations will not be saved. Please refresh to load your saved chart layouts."])}
        },
        "failed": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an issue loading your saved chart content."])}
        }
      },
      "saveChartToServer": {
        "failed": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an issue saving your changes, we will try to save them again automatically background."])}
        }
      }
    },
    "competition": {
      "active": {
        "description": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["leaderboard"])},
          "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Check the ", _interpolate(_list(0)), " here. Stats will be updated hourly."])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nickname"])}
        },
        "rank": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rank"])}
        },
        "roi": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual ROI"])}
        },
        "volume": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume"])}
        },
        "yourTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(your team)"])}
      },
      "closed": {
        "description": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["leaderboard"])},
          "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The trading competition has ended and a winner has been found. Check out the ", _interpolate(_list(0)), "."])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nickname"])}
        },
        "rank": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rank"])}
        },
        "volume": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume"])}
        }
      },
      "countdown": {
        "description": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rules and prizes"])},
          "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["You are now registered! Check out the competition ", _interpolate(_list(0)), " here."])}
        },
        "time": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Countdown"])}
        }
      },
      "firstRibbonDuringSignup": {
        "linkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])}
      },
      "individual": {
        "ends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competition ends:"])},
        "info": {
          "linkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more here."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competition info"])}
        },
        "leaderboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to leaderboard"])},
        "navbar": {
          "buttons": {
            "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
            "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participate"])},
            "signedup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signed up"])}
          }
        },
        "period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Competition period: ", _interpolate(_named("start")), " - ", _interpolate(_named("end"))])},
        "prizePool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total prize pool:"])},
        "prizeTable": {
          "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
          "prize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prize"])}
        },
        "prizes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prizes"])},
        "starts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competition starts in:"])}
      },
      "ribbon": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.bitpanda.com/pro/competition"])},
        "linkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participate in One Trading's trading competition and win up to €15,000 in BEST."])}
      },
      "signup": {
        "alreadySignedUp": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okay"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have already signed up for the competition."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already signed up"])}
        },
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participate in the trading competition"])},
        "description": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])},
          "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Participate in One Trading's trading competition and win up to €15,000 in BEST. ", _interpolate(_list(0))])}
        },
        "success": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have successfully signed up for the trading competition. Good luck!"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up completed"])}
        }
      },
      "signupModal": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "chooseTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose team"])},
        "error": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try to sign up again. If the error persists please contact support."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whoops, something went wrong"])}
        },
        "nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your random nickname"])},
        "privacy": {
          "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["I hereby consent to the processing of my personal data and I agree to be informed about the results of the Competition via email. (Privacy policy available ", _interpolate(_list(0)), ")"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here"])}
        },
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participate"])},
        "terms": {
          "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["I hereby agree to the ", _interpolate(_list(0)), "."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terms and conditions"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participate in the trading competition"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One Trading - Trading competition"])},
      "verifyModal": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to participate in the trading competition you will need to be verified."])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify now"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification needed"])}
      }
    },
    "componentSwitcher": {
      "depth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depth"])},
      "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market history"])},
      "orderbook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order book"])},
      "trade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charting"])}
    },
    "datePicker": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select time frame"])},
      "titleShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time frame"])}
    },
    "errors": {
      "errorModal": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
        "defaultMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try again later."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whoops, something went wrong"])}
      },
      "loginFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in failed - please try to log in again or contact our support if the error persists."])},
      "loginFailedClaimIssuedInFuture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make sure the time and date on your device is set correctly. For best results, please turn on the “set time and date automatically” option in your device’s system settings. Otherwise you may not be able to login or encounter issues when using One Trading."])},
      "retryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whoops, something went wrong!"])}
    },
    "exports": {
      "page": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate an export of your trades and transactions from your account(s) over a specific time frame. All reports are saved and available for re-download for up to 1 month."])},
        "newExport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New export"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export history"])}
      },
      "table": {
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No exports found"])},
        "headers": {
          "accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account(s)"])},
          "currencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency(ies)"])},
          "expiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiry date"])},
          "markets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market(s)"])},
          "requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested"])},
          "timeframe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time frame"])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])}
        }
      },
      "type": {
        "TRADES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trades"])},
        "TRANSACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])}
      }
    },
    "firebase": {
      "indexDBError": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please refresh the page."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection error"])}
      }
    },
    "funds": {
      "api": {
        "errors": {
          "AUTH_CODE_ALREADY_USED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This authentication code has been used already."])},
          "AUTH_CODE_MAX_FAILED_ATTEMPTS_REACHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have typed in an incorrect 2FA code 3 times in a row. Any action that requires 2FA has been frozen for the next 5 min."])},
          "AUTH_CODE_REJECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorization code is invalid"])},
          "AUTH_CODE_REQUIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication code is missing. Please set up 2FA in order to proceed."])},
          "BLINC_ID_NOT_UNIQUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLINC ID is already assigned to another account"])},
          "BLOCKCHAIN_ADDRESS_NOT_UNIQUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockchain address is already assigned to another account"])},
          "IBAN_NOT_UNIQUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN is already assigned to another account"])},
          "INSUFFICIENT_FUNDS": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You cannot withdraw more than your available funds ", _interpolate(_named("maxAmount")), " ", _interpolate(_named("symbol"))])},
          "INSUFFICIENT_PERMISSIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have permission"])},
          "INTERNAL_SERVER_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unexpected server error"])},
          "INVALID_ACCOUNT_HOLDER_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid account holder name"])},
          "INVALID_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have permission to withdraw this amount"])},
          "INVALID_BIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid BIC"])},
          "INVALID_BLINC_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is not a valid BLINC ID"])},
          "INVALID_BLOCKCHAIN_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is not a valid wallet address"])},
          "INVALID_CONFIRMATION_HASH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This link is no longer valid"])},
          "INVALID_COUNTRY_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid country code"])},
          "INVALID_CURRENCIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no transactions for this currency"])},
          "INVALID_CURRENCY_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid currency"])},
          "INVALID_CURSOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid cursor"])},
          "INVALID_DESTINATION_TAG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid destination tag"])},
          "INVALID_DIRECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid direction"])},
          "INVALID_FROM_QUERY_PARAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid date range"])},
          "INVALID_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid IBAN"])},
          "INVALID_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid ID"])},
          "INVALID_MAX_PAGE_SIZE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid page size"])},
          "INVALID_PAYOUT_ACCOUNT": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("transferMethod")), " account not found"])},
          "INVALID_PAYOUT_ACCOUNT_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For account name only letters, number and hyphens and length between 3-20 are allowed"])},
          "INVALID_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid status"])},
          "INVALID_TO_QUERY_PARAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid date range"])},
          "INVALID_TRANSFER_METHOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid transfer method"])},
          "MALFORMED_JSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malformed JSON"])},
          "MAX_PAGE_SIZE_EXCEEDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid page size"])},
          "MISSING_ACCOUNT_HOLDER_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account holder name is missing"])},
          "MISSING_BIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing BIC/SWIFT"])},
          "MISSING_CONFIRMATION_HASH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This link is not valid"])},
          "MISSING_CURRENCY_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency not found"])},
          "MISSING_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing IBAN"])},
          "MISSING_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing ID"])},
          "MISSING_TRANSFER_METHOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer method not found"])},
          "NetworkError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unexpected server error"])},
          "PAYOUT_ACCOUNT_CURRENCY_MISMATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency not matching"])},
          "PAYOUT_ACCOUNT_INACTIVE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("accountName")), " inactive"])},
          "PAYOUT_ACCOUNT_NOT_FOUND": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("accountName")), " account not found"])},
          "TRANSACTION_ALREADY_FINAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction cannot be cancelled anymore"])},
          "TRANSACTION_NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction not found"])},
          "TRANSFER_METHOD_DISABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer method is disabled"])},
          "TRANSFER_METHOD_MISMATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer method not matching"])},
          "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unexpected server error"])}
        },
        "transferMethods": {
          "ADJUSTMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjustment"])},
          "BLINC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLINC Network"])},
          "BLOCKCHAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockchain"])},
          "CARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card"])},
          "INSTANT_TRADE_ACCOUNT_TRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap transfer"])},
          "INTERNAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitpanda"])},
          "SEPA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEPA bank transfer"])},
          "SIGNET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signet"])},
          "SUBACCOUNT_TRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subaccount transfers"])},
          "SWIFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SWIFT bank transfer"])}
        }
      }
    },
    "fundsAccounts": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API"])},
      "buttons": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add account"])},
        "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])}
      },
      "delete": {
        "buttons": {
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, delete it"])}
        },
        "failed": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to delete payout account."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])}
        },
        "message": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to remove a payout account. This action cannot be undone.\n\nAre you sure you want to proceed?"])},
          
        ],
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])}
      },
      "description": {
        "BLINC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use BLINC Network for instant fiat transactions"])},
        "SEPA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use SEPA accounts for fiat transactions"])},
        "SIGNET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use SIGNET accounts for fiat transactions"])},
        "SWIFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use SWIFT accounts for fiat transactions"])},
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use payout accounts for fiat transactions"])}
      },
      "headers": {
        "accountName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account label"])},
        "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC/SWIFT"])},
        "bicSwift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC/SWIFT"])},
        "bitpandaBlincId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLINC ID"])},
        "blincId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLINC ID"])},
        "blockchainAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockchain address"])},
        "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
        "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
        "signetAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signet address"])},
        "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])}
      },
      "labels": {
        "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account label"])},
        "bank_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank"])},
        "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC/SWIFT"])},
        "blinc_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLINC ID"])},
        "blockchain_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet address"])},
        "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
        "destinationTag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination Tag"])},
        "destination_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination tag"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
        "memo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memo"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
        "payout_receiver_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "signet_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signet address"])}
      },
      "states": {
        "CREATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linked"])},
        "DEACTIVATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivated"])},
        "PENDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
        "REJECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])}
      },
      "tabNames": {
        "BLINC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLINC"])},
        "SEPA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEPA"])},
        "SIGNET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIGNET"])},
        "SWIFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SWIFT"])}
      }
    },
    "futuresOrderForm": {
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "availableMargin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available margin"])},
      "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy/Long"])},
      "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract"])},
      "equity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equity"])},
      "leverageButtons": {
        "10x": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10x"])}
        },
        "2x": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2x"])}
        },
        "isolated": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISOLATED"])}
        }
      },
      "maxPosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max Position"])},
      "minPosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min Position"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
      "requiredMargin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Margin required"])},
      "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell/Short"])},
      "tpsl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TP/SL"])}
    },
    "header": {
      "navButtons": {
        "futures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Futures"])},
        "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade"])},
        "spot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exchange"])}
      }
    },
    "instantTrade": {
      "account": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap account"])},
        "restricted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account is not currently enabled for Swap, please contact Customer Care"])}
      },
      "inputValidations": {
        "maxAmount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Amount above available balance of ", _interpolate(_named("maxAmount")), " ", _interpolate(_named("symbol"))])},
        "minAmount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Minimum order size has to be at least ", _interpolate(_named("minAmount")), " ", _interpolate(_named("symbol"))])}
      },
      "panel": {
        "button": {
          "cancelQuote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel quoting"])},
          "exchangePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execute with an obligation to pay"])},
          "getPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get quote"])},
          "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])}
        },
        "countdown": {
          "line": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Quoting open for ", _interpolate(_named("time"))])},
          "time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("min")), " min ", _interpolate(_named("sec")), " sec"])}
        },
        "guaranteedRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current rate"])},
        "inputs": {
          "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to exchange"])},
          "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will receive"])}
        },
        "legal": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price moves with market. Execution is at last price displayed."])}
        },
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy and sell digital assets in a fast, simple, and secure way. Trade up to €5 million in one click."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap"])}
      },
      "tradeFeedback": {
        "currencyChange": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have changed the currency. The live quote has been cancelled."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency change detected"])}
        },
        "disabledPair": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are currently unable to quote for this pair. Please try again later."])}
        },
        "emptyAccount": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer funds"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You currently have no funds in your Swap account. You can still get quotes to see the available prices, but you will not be able to trade until you transfer funds from your main account to your Swap account."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient funds"])}
        },
        "genericError": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])}
        },
        "insufficient": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add funds"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have enough funds to complete this trade. Please add sufficient funds to proceed."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient funds"])}
        },
        "insufficientBalance": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are currently unable to offer this crypto-asset. Please try again later."])}
        },
        "insufficientCredit": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are currently unable to offer this crypto-asset. Please try again later."])}
        },
        "noPricesAvailable": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no prices available for the coins you have selected."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No prices available"])}
        },
        "orderMinimum": {
          "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Order quantity ", _interpolate(_named("quantity")), " ", _interpolate(_named("asset")), " is less than the minimum size ", _interpolate(_named("size")), " ", _interpolate(_named("asset")), " for ", _interpolate(_named("pair"))])}
        },
        "refreshQuote": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh quote"])},
          "actionSecondary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start over"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The quote you requested has expired. Please initiate a new quote."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh quote"])}
        },
        "transferFunds": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer funds"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer funds from your Main account to your Swap account."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer your Funds"])}
        },
        "unavailable": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are unable to reach the Swap server at the moment, please try again later."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])}
        }
      },
      "tradeSuccess": {
        "buttons": {
          "tradeAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade again"])},
          "viewHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View history"])}
        },
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade successful!"])},
        "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Congratulations, you have successfully traded ", _interpolate(_named("sourceAmount")), " ", _interpolate(_named("sourceCurrency")), " into ", _interpolate(_named("targetAmount")), " ", _interpolate(_named("targetCurrency")), "."])}
      }
    },
    "login": {
      "formOverlayLoginButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in to trade"])},
      "formOverlaySignupButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
      "loggingYouInMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logging you in..."])},
      "navBarLoginButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
      "navBarSignupButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
      "toasts": {
        "failed": {
          "localStorageMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try again in a moment and if the problem persists, try clearing your browsers local storage."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try again in a moment"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in failed"])}
        }
      }
    },
    "market": {
      "inMaintenance": {
        "overlay": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The market is currently in maintenance mode. We are working as hard as we can to bring it up to speed again."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market currently in maintenance"])}
        },
        "ribbon": {
          "statusLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the operational status for further information."])},
          "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("market")), " is currently undergoing maintenance."])}
        }
      },
      "new": {
        "overlay": {
          "inlineURL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#Deposit"])},
          "inlineURLText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["depositing funds"])},
          "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The new market is still not open for trading, but you can already start ", _interpolate(_named("inlineUrl")), "."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We are launching the ", _interpolate(_named("market")), " market"])}
        }
      },
      "postOnly": {
        "overlay": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only advanced orders with Post-Only execution will be accepted. Trading will be enabled soon."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("market")), " is currently in Post-Only mode"])}
        }
      },
      "suspended": {
        "overlay": {
          "statusLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check operational status here."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have temporarily suspended the market. We are working as hard as we can to bring it up to speed again."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market currently suspended"])}
        },
        "ribbon": {
          "statusLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the operational status for further information."])},
          "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("market")), " is currently suspended."])}
        }
      }
    },
    "marketDetails": {
      "24hHigh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24h high"])},
      "24hLow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24h low"])},
      "24hPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24h price change"])},
      "24hPriceShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24h change"])},
      "24hVolume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24h volume"])},
      "currentPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last price"])}
    },
    "marketHistory": {
      "header": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last price"])},
        "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market history"])}
    },
    "marketList": {
      "header": {
        "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24h %"])},
        "chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24h price chart"])},
        "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "pairVolume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pair/Volume"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last price"])},
        "priceAndChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24h change"])},
        "priceChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price/24h change"])},
        "trend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trend"])},
        "volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vol"])}
      },
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
      "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search markets"])},
      "tabs": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
        "btc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BTC"])},
        "eur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markets"])}
    },
    "marketListMenu": {
      "dropdownTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market"])},
      "tabs": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
        "btc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BTC"])},
        "eur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markets"])}
    },
    "modals": {
      "unableToConnect": {
        "eotc": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to connect"])}
        },
        "exchange": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to connect to the exchange"])}
        }
      }
    },
    "myOrders": {
      "cancelAllOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel all"])},
      "header": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
        "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
        "availableMargin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available margin"])},
        "averagePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average price"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
        "eligibleForCollateral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eligible for collateral"])},
        "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee"])},
        "filled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filled"])},
        "lastSettlement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last settlement"])},
        "marginUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Margin used"])},
        "markPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark price"])},
        "orderType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Type"])},
        "positionValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position value"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booked price"])},
        "priceShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
        "realisedPnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realised PnL"])},
        "side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Side"])},
        "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop"])},
        "stopPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop price"])},
        "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market"])},
        "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
        "totalBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total balance"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "unrealisedPnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unrealised PnL"])},
        "usedForMargin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Used for margin"])},
        "usedMargin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Used margin"])}
      },
      "helpers": {
        "loginToSee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in to see orders"])},
        "noFills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no filled orders in the last 24h."])},
        "noFillsForMarket": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have no filled orders for ", _interpolate(_named("market")), " in the last 24h."])},
        "noMoreFills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have not had any more filled orders in the last 24h."])},
        "noOpenOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no open orders."])},
        "noOpenOrdersForMarket": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have no open orders for ", _interpolate(_named("market")), "."])},
        "noOpenPositions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no open positions."])},
        "noOpenPositionsForMarket": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have no open ", _interpolate(_named("market")), " positions."])},
        "seeFullHistory": {
          "linkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["orders"])},
          "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Head over to ", _interpolate(_named("linkText")), " to see your full history."])}
        },
        "seeHistory": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Head over to"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["orders"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to see your full history."])},
          
        ]
      },
      "settings": {
        "showAllMarkets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all markets"])},
        "showSelectedMarket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show selected market"])}
      },
      "tabs": {
        "balances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balances"])},
        "filled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filled"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
        "openOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Orders"])},
        "positions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positions"])}
      },
      "titles": {
        "filled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Orders"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Orders"])}
      },
      "toasts": {
        "cancelled": {
          "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to cancel order"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order has been cancelled"])},
          "reasons": {
            "IN_MAINTENANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market currently in maintenance"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order cancelled"])}
        },
        "created": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order was successful created."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order created"])}
        },
        "filled": {
          "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filled"])}
        },
        "rejected": {
          "reason": {
            "DEFAULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unforeseen service disruptions"])},
            "INSUFFICIENT_FUNDS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient funds"])},
            "INSUFFICIENT_LIQUIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient liquidity"])},
            "MATCHING_MOVE_FAILED_PRICE_OVER_RISK_LIMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price exceeds risk limit"])},
            "MATCHING_POST_ONLY_RESULTS_IN_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post-only order would match immediately"])},
            "MOVE_SELF_TRADE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self trade prevented"])},
            "PRICE_OUT_OF_COLLAR_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price not near market"])},
            "RISK_FAILED_OVER_MAX_POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position size limit exceeded"])},
            "RISK_INVALID_RESERVE_BID_PRICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid reserve bid price"])},
            "RISK_MARGIN_TRADING_DISABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Margin trading disabled"])},
            "SELF_TRADE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self trade prevented"])},
            "SLIPPAGE_PREVENTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slippage tolerance exceeded"])},
            "USER_MGMT_USER_IN_DERISK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account in derisk"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order failed"])},
          "titleRejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order rejected"])}
        },
        "stopOrder": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop order triggered"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Triggered"])}
        }
      }
    },
    "nativeApps": {
      "updateAvailable": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not now"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update now"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have added new functionality and fixed some bugs to make your experience as smooth as possible."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New update available"])}
      }
    },
    "orderForm": {
      "actions": {
        "buy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Buy ", _interpolate(_named("symbol"))])},
        "sell": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sell ", _interpolate(_named("symbol"))])}
      },
      "advancedOptions": {
        "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced:"])},
        "cancelAfter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel after:"])},
        "cancelAfterOptions": {
          "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 day"])},
          "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 hour"])},
          "hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("number")), " hours"])},
          "mins": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("number")), " min"])},
          "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 week"])}
        },
        "executionHints": {
          "ALLOW_TAKER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allows order to be both taker or maker."])},
          "FILL_OR_KILL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders can only be fully filled or cancelled. Taker fees will apply."])},
          "IMMEDIATE_OR_CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order can be partially filled and cancelled. Taker fees will apply."])},
          "POST_ONLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order will only execute as a maker."])},
          "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allows order to be both taker or maker."])},
          "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order will only execute as a maker."])}
        },
        "failedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving advanced options failed"])},
        "hint": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.onetrading.com/hc/en-gb/articles/16346548515473-Which-order-types-are-available-on-One-Trading-"])}
        },
        "timeInForce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time in force:"])},
        "timeInForceNames": {
          "FILL_OR_KILL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill or Kill"])},
          "GOOD_TILL_CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good 'til cancelled"])},
          "GOOD_TILL_TIME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good 'til Time"])},
          "IMMEDIATE_OR_CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immediate or Cancel"])},
          "POST_ONLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post only"])}
        },
        "timeInForceNamesShort": {
          "FILL_OR_KILL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FoK"])},
          "GOOD_TILL_CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GtC"])},
          "GOOD_TILL_TIME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GtT"])},
          "IMMEDIATE_OR_CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IoC"])},
          "POST_ONLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PO"])}
        }
      },
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "amountToReceive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total (excl. fees)"])},
      "availableBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available:"])},
      "errors": {
        "BAD_AMOUNT_PRECISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad amount precision"])},
        "BAD_PRICE_PRECISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad price precision"])},
        "BAD_TRIGGER_PRICE_PRECISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad trigger price precision"])},
        "INSUFFICIENT_FUNDS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient funds"])},
        "INSUFFICIENT_LIQUIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient liquidity"])},
        "INVALID_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This amount is not valid"])},
        "INVALID_PRICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid price"])},
        "IN_MAINTENANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market currently in maintenance"])},
        "MATCHING_MOVE_FAILED_PRICE_OVER_RISK_LIMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price exceeds risk limit"])},
        "MATCHING_POST_ONLY_RESULTS_IN_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post-only order would match immediately"])},
        "MAX_OPEN_ORDERS_EXCEEDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximal amount of open orders exceeded"])},
        "MIN_SIZE_NOT_SATISFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order size is too low"])},
        "MOVE_SELF_TRADE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self trade prevented"])},
        "NEGATIVE_PRICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negative price is not allowed"])},
        "NO_TRADING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market currently suspended"])},
        "ONLY_LIMIT_ORDERS_ALLOWED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently only limit orders are accepted"])},
        "PRICE_OUT_OF_COLLAR_ERROR": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("market")), " order rejected – price not near market. Adjust and retry."])},
        "RISK_FAILED_OVER_MAX_POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position size limit exceeded"])},
        "RISK_INVALID_RESERVE_BID_PRICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid reserve bid price"])},
        "RISK_MARGIN_TRADING_DISABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Margin trading disabled"])},
        "SELF_TRADE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self trade prevented"])},
        "SUSPENDED_TRADING_FOR_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are currently not able to trade with this account. Please contact our support for further information."])},
        "USER_MGMT_USER_IN_DERISK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account in derisk"])},
        "VERIFICATION_NEEDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to verify your account before trading is allowed."])},
        "VERSION_TOO_OLD": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["To trade ", _interpolate(_named("market")), ", please update to the latest version."])},
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong, please try again"])}
      },
      "estimatedTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Est. Total"])},
      "fee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fee (", _interpolate(_named("feeValue")), "%)"])},
      "limitPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit price"])},
      "limits": {
        "reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open orders limit reached"])}
      },
      "lockedBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locked:"])},
      "marketOrderSlippage": {
        "linkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["active"])},
        "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Slippage Protection is ", _interpolate(_named("linkText")), " (", _interpolate(_named("value")), ")"])}
      },
      "marketOrderTotalHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price shown is based on the last executed trade. Order may result in execution at a significantly less favourable price."])},
      "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max"])},
      "maxPositionSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max position size:"])},
      "modals": {
        "marketMove": {
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
          "checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don’t show this again"])},
          "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We have calculated that this ", _interpolate(_named("orderType")), " order will affect the market considerably. Completing this order could result in significant losses. Are you sure you want to proceed?"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place order"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning!"])}
        }
      },
      "postOnlyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market is currently in Post-Only mode. Use the advanced order types for limit orders to create orders."])},
      "stopPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop price"])},
      "toasts": {
        "overload": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try again in a moment"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System currently overloaded"])}
        }
      },
      "tooltips": {
        "amount": {
          "insufficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your BEST balance might not be sufficient to pay trading fees with BEST."])}
        },
        "icon": {
          "feesInBest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay trading fees with BEST is enabled"])},
          "feesNotInBest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Use BEST to save ", _interpolate(_named("value")), "% on trading fees"])}
        }
      },
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
      "usd": {
        "agreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree that deposit and withdrawal of USD is only available for institutional customers with a Signet account, and may not be possible at all times."])}
      },
      "validation": {
        "decimal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only decimal numbers are allowed"])},
        "maxPrice": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The maximum price cannot exceed ", _interpolate(_named("maxPrice")), " ", _interpolate(_named("symbol"))])},
        "minAmount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Minimum amount has to be at least ", _interpolate(_named("minAmount")), " ", _interpolate(_named("symbol"))])},
        "minAmountForMarketOrder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Minimum order size has to be at least ", _interpolate(_named("minAmountForMarketOrder")), " ", _interpolate(_named("symbol"))])},
        "minPrice": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The minimum price must be at least ", _interpolate(_named("minPrice")), " ", _interpolate(_named("symbol"))])},
        "minTotal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Minimum order size has to be at least ", _interpolate(_named("minTotal")), " ", _interpolate(_named("symbol"))])},
        "positiveNumbersOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A number greater than 0 must be entered!"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry is required"])}
      }
    },
    "orderbook": {
      "compile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compile"])},
      "lastMatchedPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last matched price"])},
      "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
      "separator": {
        "compile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compile"])},
        "currentSpread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current spread (BPS)"])},
        "lastMatchedPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last matched price"])},
        "lastMatchedPriceShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last price"])},
        "spread": {
          "basePoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current spread (BPS)"])},
          "quoteCurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current spread"])}
        }
      },
      "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SUM"])},
      "tabs": {
        "depthChart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depth"])},
        "marketHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
        "orderbook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book"])}
      },
      "titles": {
        "depthChart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depth chart"])},
        "marketHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market history"])},
        "orderbook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order book"])}
      },
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
    },
    "payoutAccounts": {
      "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API"])},
      "buttons": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New fiat account"])},
        "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
        "documentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation"])}
      },
      "cannotCreateAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to create payout account."])},
      "copyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID was copied to the clipboard."])},
      "creation": {
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account creation failed"])}
      },
      "delete": {
        "buttons": {
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, delete it"])}
        },
        "failed": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to delete payout account."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])}
        },
        "message": [
          (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You are about to delete the following payout account: ", _interpolate(_named("name"))])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete it?"])},
          
        ],
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete fiat account?"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the payout IDs for automated fiat withdrawals"])},
      "errorMessages": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try reloading the page. Should the problem persists, please contact our support."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error when fetching your Bank account details"])}
      },
      "headers": {
        "bankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank name"])},
        "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC/SWIFT"])},
        "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
        "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout ID"])},
        "payoutReceiverName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient's name"])}
      },
      "tabName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiat accounts"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiat payout accounts"])}
    },
    "profile": {
      "distribution": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution"])}
      },
      "exploreFAQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore the FAQs"])},
      "getApp": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade anytime, anywhere on the world’s fastest-ever trading venue"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get the mobile app"])}
      },
      "login": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View your overall balance and track it over time"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in to access your portfolio"])}
      },
      "needHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need help?"])},
      "portfolio": {
        "noFunds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No funds available"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])}
      },
      "trending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trending"])}
    },
    "rateLimitTracker": {
      "toasts": {
        "hit": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have hit your rate limit"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate limit hit"])}
        },
        "warning": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are close to hitting your rate limit"])}
        }
      }
    },
    "ribbons": {
      "accountRatio": {
        "DERISK": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To avoid potential liquidation, please top up your account."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account is currently in Derisk mode."])}
        },
        "LIQUIDATION": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account has been liquidated."])}
        }
      },
      "apiDoc": {
        "message": {
          "linkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["link"])},
          "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["API users please see ", _interpolate(_named("linkText")), " for required updates to access F.A.S.T"])}
        }
      },
      "importantAnnouncement": {
        "message": {
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["During this period, all trading activities will be suspended and open orders will be automatically closed. Thank you for your understanding."])},
          "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["There will be downtime for a platform upgrade ", _interpolate(_named("start")), " to ", _interpolate(_named("end")), " (subject to change)."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important Notice: Platform Upgrade"])}
        }
      },
      "importantAnnouncementPrep": {
        "message": {
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markets to remain open are: BTC/USDT, BTC/EUR, ETH/EUR, USDT/EUR, XRP/EUR, SOL/EUR."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In preparation for the launch of our new market leading trading venue F.A.S.T, we will be suspending a number of markets on our Exchange. This does not impact your ability to deposit and withdraw assets or trade these assets plus many more on Swap."])}
        }
      },
      "marketPromotion": {
        "message": {
          "linkHref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.onetrading.com/blog"])},
          "linkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New USD pairs launched. Trade now!"])}
        }
      },
      "newVersion": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["update now"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have released a new version of One Trading, "])}
      },
      "twoFa": {
        "button": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable 2FA"])}
        },
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to improve the security of your account we encourage you to enable two-factor authentication (2FA)."])}
      },
      "verify": {
        "button": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here to proceed"])}
        },
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account verification is incomplete or needs to be updated to meet legal requirements. Please complete your verification now to access our services."])},
        "textNotReady": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming soon! Customer verification process, trading and deposits will open soon. Stay tuned!"])}
      }
    },
    "singleMarketSlider": {
      "charting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charting"])},
      "depth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depth"])},
      "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market history"])},
      "myOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent orders"])},
      "trade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade"])},
      "tradingView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trading View"])}
    },
    "subaccount": {
      "bfc": {
        "disabledReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BEST fee collection does not apply for subaccounts"])}
      },
      "close": {
        "form": {
          "close": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Close ", _interpolate(_named("name")), "?"])},
          "closeAreYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to close it?"])},
          "closeConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, close"])},
          "closeWithBalance": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You are about to close ", _interpolate(_named("name")), ". All balances on the subaccount will be transferred to your main account. This step cannot be undone."])},
          "closeWithoutBalance": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You are about to close ", _interpolate(_named("name")), ". This step cannot be undone."])},
          "limitReachedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the limit of maximum allowed closed subaccounts. Please contact One Trading Support."])},
          "withOpenOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action required"])},
          "withOpenOrdersOrBalanceText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["To close ", _interpolate(_named("name")), " please cancel all orders and move your funds to another subaccount or main."])},
          "withOpenOrdersText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["In order to close ", _interpolate(_named("name")), ", you will have to cancel active orders."])}
        },
        "toast": {
          "success": {
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " successfully closed."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subaccount closed"])}
          }
        }
      },
      "create": {
        "form": {
          "buttons": {
            "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
            "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
            "okay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okay"])},
            "setupTwoFa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set up 2FA"])},
            "verifyNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify now"])}
          },
          "createNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New subaccount"])},
          "haveToVerifyText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account must be fully verified in order to open subaccount."])},
          "haveToVerifyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification required"])},
          "limitReachedAttention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention"])},
          "limitReachedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the limit of maximum allowed active subaccounts. If you want to create a new subaccount, try closing an existing. Otherwise please contact support."])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
          "namePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name new subaccount"])},
          "twoFaText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please set up 2FA in order to proceed."])},
          "twoFaTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA is required"])},
          "validations": {
            "name": {
              "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicated names are not allowed"])},
              "matchesRegex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only letters, number and hyphens are allowed"])},
              "maxLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only 20 characters are allowed"])},
              "minLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name should be 3 or more characters"])},
              "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The name is required"])}
            }
          }
        },
        "toast": {
          "SUBACCOUNT_NAME_NOT_UNIQUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot create a subaccount with the same name as your previous subaccounts."])}
        }
      },
      "list": {
        "addNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New subaccount"])},
        "table": {
          "balances": {
            "allAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All accounts"])},
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No balances found"])},
            "emptyFiltered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nothing found with the applied filter"])}
          },
          "headers": {
            "balances": {
              "coin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coin"])},
              "locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locked in orders"])},
              "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
              "valueIn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Value in ", _interpolate(_named("symbol"))])}
            },
            "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
            "total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Total balance (", _interpolate(_named("symbol")), ")"])}
          },
          "item": {
            "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
            "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer"])}
          },
          "noItemsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no subaccounts yet"])}
        },
        "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer"])}
      },
      "switcher": {
        "currentSubaccount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Subaccount: ", _interpolate(_named("value"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub accounts"])}
      },
      "tabs": {
        "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subaccounts"])},
        "transfers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfers"])}
      },
      "toasts": {
        "switch": {
          "success": {
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You are now on ", _interpolate(_named("subaccountName"))])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switching subaccount"])}
          }
        }
      },
      "transfers": {
        "table": {
          "headers": {
            "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])}
          },
          "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiver"])},
          "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender"])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subaccount transfers"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subaccount transfers"])}
      }
    },
    "tiers": {
      "headers": {
        "currentTier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your current fees"])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fee levels are calculated by summing up your total trading volume in ", _interpolate(_named("currency")), " over the past 30 days (excluding today) including subaccounts. The higher your total trading volume, the lower your fees become. The total trading volume is calculated in ", _interpolate(_named("currency")), " every 24 hours."])},
        "makerFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maker w/o BEST"])},
        "makerFeeDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maker"])},
        "messageTop": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee levels are calculated by your total trading volume in BTC over the past 30 days (excluding today). The higher your trading volume, the lower your fees become."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On each fee level there is a maker fee (when you add liquidity to the order book) and a taker fee (when you fill an order). Maker fee is incentivised through lower fees compared to taker fees. Total trading volume is calculated in BTC every 24 hours."])},
          
        ],
        "takerFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taker w/o BEST"])},
        "takerFeeDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taker"])},
        "tier": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Level ", _interpolate(_named("level")), " (", _interpolate(_named("volume")), " ", _interpolate(_named("currency")), ")"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels"])},
        "topMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee levels are calculated by summing up your total trading volume in BTC over the past 30 days (excluding today) including subaccounts. The higher your total trading volume, the lower your fees become. The total trading volume is calculated in BTC every 24 hours."])},
        "volumeLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume"])}
      },
      "levels": {
        "currentTier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Current level)"])},
        "tier": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Level ", _interpolate(_named("level"))])}
      },
      "pageHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee levels"])}
    },
    "tooltip": {
      "fallbackReason": {
        "INSUFFICIENT_FUNDS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trading fees for this trade were not paid in BEST."])},
        "LOCKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BEST funds locked in orders, therefore trading fees were not paid in BEST."])},
        "PRICING_UNAVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing unavailable, therefore trading fees were not paid in BEST."])},
        "terms": {
          "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available:"])},
          "locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locked:"])},
          "needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required:"])}
        }
      }
    },
    "tradesHistory": {
      "headers": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
        "amountReceived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received amount"])},
        "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee"])},
        "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market"])},
        "orderId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order ID"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
        "side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Side"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
        "tradeDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade details"])},
        "tradeId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade ID"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade history"])}
    },
    "tradingChart": {
      "tabs": {
        "depthChart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depth chart"])},
        "priceChart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price chart"])}
      },
      "titles": {
        "depthChart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depth"])},
        "priceChart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chart"])}
      }
    },
    "transactions": {
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "amountInput": {
        "validations": {
          "decimal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only numbers are allowed"])},
          "maxAmount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have exceeded your maximum amount ", _interpolate(_named("maxAmount")), " ", _interpolate(_named("symbol"))])},
          "maxAmountDaily": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You are about to exceed the maximum daily amount ", _interpolate(_named("maxAmountDaily")), " ", _interpolate(_named("symbol"))])},
          "minAmount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The entered amount must be at least ", _interpolate(_named("minAmount")), " ", _interpolate(_named("symbol"))])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field is required"])}
        }
      },
      "confirmEmailModal": {
        "additionalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please go to your email inbox and confirm the withdrawal."])},
        "confirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have sent you a confirmation email."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm email"])}
      },
      "confirmWithdraw": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your inbox. We have sent you an activation link. \nPlease ensure you also check your spam folder."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm withdrawal"])}
      },
      "currencySelector": {
        "headline": {
          "amountAndEurValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount/EUR value"])},
          "amountAndFiatValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Amount/", _interpolate(_named("fiat")), " value"])},
          "selectCurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select currency"])}
        }
      },
      "deposit": {
        "card": {
          "external": {
            "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
            "changeDepositMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change deposit method"])},
            "depositAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit amount"])},
            "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee"])},
            "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
            "payWithCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay with card"])},
            "validation": {
              "maxAmount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maximum deposit amount is ", _interpolate(_named("maxAmount")), " ", _interpolate(_named("symbol"))])},
              "minAmount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Minimum deposit amount is ", _interpolate(_named("minAmount")), " ", _interpolate(_named("symbol"))])},
              "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount is required"])}
            }
          }
        },
        "crypto": {
          "external": {
            "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
            "buttons": {
              "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])}
            },
            "canCreateMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can generate another address."])},
            "cannotCreateReasons": {
              "user-wallet-deposit-address-creation-not-possible-for-non-default-wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can only generate new deposit addresses on your default wallet."])},
              "user-wallet-deposit-address-for-not-allowed-cryptocoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit is inactive right now."])},
              "user-wallet-deposit-address-maximum-amount-of-miota-addresses-reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the maximum of 10 IOTA addresses per 24 hours"])},
              "user-wallet-deposit-address-trying-to-create-another-erc20-address": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We currently don't support multiple addresses for ", _interpolate(_named("currency")), " - ERC20 coins"])},
              "user-wallet-error-create-deposit-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to create a new deposit address. Please contact our support."])},
              "user-wallet-last-deposit-address-not-used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must use your last wallet address before this action can be done."])},
              "user-wallet-last-deposit-address-not-used-and-destination-tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must use your last destination tag before this action can be done."])}
            },
            "copyAddressMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("symbol")), " address was copied to the clipboard."])},
            "copyDestinationTagMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("destinationTag")), " was copied to the clipboard."])},
            "destinationTag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("destinationTag"))])},
            "generalWarning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Warning! Don't send any other cryptocurrencies or assets than ", _interpolate(_named("symbol")), " to this address, or you will lose them."])},
            "generateAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate another address"])},
            "maintenance": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, we are currently undergoing maintenance…"])}
            },
            "tabTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit address"])},
            "transactionFeeNote": {
              "feeWhenSmallerThan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit fee will be charged if the amount is smaller than:"])},
              "minimumAmount": {
                "additionalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heads up! The coins will be lost if you send less than the minimum deposit amount to your One Trading wallet."])},
                "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum deposit amount"])}
              },
              "walletFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet deposit fee:"])}
            },
            "unavailable": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, deposits are disabled at the moment."])}
            },
            "verification": {
              "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Before you can deposit ", _interpolate(_named("currency")), " you will have to verify your account. Click “Verify now” to start the verification process."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to verify your account"])},
              "verifyButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify now"])}
            }
          }
        },
        "currencySelectorPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select currency"])},
        "fiat": {
          "external": {
            "bank": {
              "buttons": {
                "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])}
              }
            },
            "blinc": {
              "infoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make sure to always include the payment reference in your transaction, otherwise we can’t match the payment to your account."])},
              "tableLabels": {
                "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
                "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])},
                "bitpandaBlincId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLINC ID"])},
                "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
                "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference"])}
              }
            },
            "buttons": {
              "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
              "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
              "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])}
            },
            "depositFiatFailure": {
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, something went wrong. Please try again later."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsuccessful deposit"])}
            },
            "depositFiatSuccess": {
              "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have successfully deposited ", _interpolate(_named("currency")), " ", _interpolate(_named("amount")), " via ", _interpolate(_named("payment")), "."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit successful!"])}
            },
            "depositFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit from"])},
            "depositLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24h deposit limit"])},
            "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free"])},
            "funds": {
              "infoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make sure to always include the payment reference in your transaction, otherwise we can’t match the payment to your account."])},
              "infoTextBankProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please use the details below to initiate a fiat bank transfer through your bank."])},
              "tableLabels": {
                "accountHolderId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account holder ID"])},
                "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
                "bankAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank address"])},
                "bankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank name"])},
                "beneficiaryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficiary address"])},
                "beneficiaryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficiary name"])},
                "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])},
                "bitpandaBlincId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLINC ID"])},
                "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
                "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference"])},
                "signetWalletId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signet address"])}
              }
            },
            "goToPaymentProvider": {
              "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to payment provider"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forwarding you to the payment provider.\n\nThe payment process will open in a new window. Follow the steps provided there to complete your transaction."])},
              "nothingHappening": {
                "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure to enable pop-ups or click below to be forwarded to the payment provider."])},
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nothing happening?"])}
              },
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit"])}
            },
            "providerTerms": {
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The bank account must be in your name, to ensure that the deposit will be credited to your account properly."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I hereby confirm that the bank account is in my name."])}
            },
            "sepa": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Information couldn't be loaded!"])},
              "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make sure to always include the payment reference in your transaction, otherwise we can’t match the payment to your account! Also, double-check that the bank account is in your name."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer details"])},
              "tableLabels": {
                "accountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account number"])},
                "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank address"])},
                "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
                "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our bank"])},
                "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])},
                "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
                "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
                "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
                "receiverAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our address"])},
                "sortCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort code"])},
                "uniquePaymentNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment reference"])},
                "uniquePaymentNumberWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure to include this"])},
                "valueChangedWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ensure that you transfer in using this IBAN"])}
              }
            },
            "transactionFeeNote": {
              "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEPA transactions are free."])},
              "havaleTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havale transactions can be processed 24/7, while EFT transactions can be processed within working hours. EFT transactions performed outside of working hours will be processed on the next working day."])},
              "sepaTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A SEPA transaction usually takes 2 to 3 business days."])}
            },
            "transferTo": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit to"])},
              "value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("currency")), " wallet on One Trading"])}
            },
            "validation": {
              "decimal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only numbers are allowed!"])},
              "maxAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have exceeded your maximum deposit amount"])},
              "minAmount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The entered amount must be at least ", _interpolate(_named("minAmount")), " ", _interpolate(_named("symbol"))])},
              "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field is required!"])}
            },
            "verification": {
              "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Before you can deposit ", _interpolate(_named("currency")), " you will have to verify your account. Click “Verify now” to start the verification process."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to verify your account"])},
              "verifyButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify now"])}
            }
          }
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit"])},
        "toasts": {
          "confirmed": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funds have been credited"])}
          }
        },
        "verification": {
          "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Before you can deposit ", _interpolate(_named("currency")), " you will have to verify your account. Click “Verify now” to start the verification process."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to verify your account"])},
          "verifyButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify now"])}
        }
      },
      "depositStatusModal": {
        "error": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try again."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction failed!"])}
        },
        "secondaryAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View transactions"])},
        "success": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The funds will be available in your account soon. Check your transaction history to see the progress."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction successful!"])}
        }
      },
      "errors": {
        "amountIsTooHigh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specified amount is greater than your available balance (including pending transactions)"])},
        "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong! Please try again later."])},
        "userContactYourself": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are trying to send to your own address."])},
        "userTransactionAddressNotMatchWallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The supplied address does not match with the wallet cryptocurrency"])},
        "userTransactionWaitAfterPwReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For security reasons, you have to wait a total of 48 hours after a password or 2FA reset until you are able to withdraw again."])},
        "userWithdrawOneUnconfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is at least one unconfirmed withdrawal. Please confirm or cancel it to continue."])}
      },
      "maxTransferAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max"])},
      "methodSelector": {
        "bankTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Transfer"])},
        "creditDebit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit/Debit Card"])},
        "crypto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto"])},
        "depositCrypto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit crypto from another wallet or exchange."])},
        "instantlyDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instantly deposit funds with any debit or credit card, Apple Pay, and Google Pay."])},
        "recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended"])},
        "transferFunds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer funds from your bank account."])}
      },
      "newAddressGeneration": {
        "additionalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click Generate below to generate an address."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate"])},
        "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["In order to deposit to your ", _interpolate(_named("currencyName")), " address you need to generate a deposit address."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please generate a new address"])}
      },
      "noFundingMethods": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account has no available funding methods."])},
        "linkHref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.onetrading.com/hc/en-gb"])},
        "linkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["support"])},
        "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please contact ", _interpolate(_named("linkText")), "."])}
      },
      "payment": {
        "FAGG22": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I hereby agree to the early fulfillment of this transaction. Thus, I forgo my right of withdrawal of the contract (under the respective national implementation of Sec 16.m Consumer Rights Directive)."])}
        },
        "providerTerms": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment account must be in your name, to ensure that the deposit will be credited to your account properly."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I hereby confirm that the payment account is in my name."])}
        },
        "transactionSpeedInfo": {
          "buttonCollapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapse"])},
          "buttonReadMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])},
          "fullDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are multiple reasons why the transfers might not happen instantly:\n- if the transaction is above a undisclosed threshold\n- if your bank is not a part of the SEPA Instant network\n- if you haven’t enabled Instant SEPA transfers in your net bank (Some banks requires this)"])},
          "shortDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If your bank supports SEPA Instant, transfers up to €100,000 arrive within minutes. Otherwise it might take 1-3 business days."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction speed"])}
        }
      },
      "paymentOptions": {
        "bitpandaWallet": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bitpanda ", _interpolate(_named("value")), " wallet"])},
        "depositMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit method"])},
        "depositMethodPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose deposit method"])},
        "externalWallet": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["External ", _interpolate(_named("value")), " wallet"])},
        "feeWrapper": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), "%"])},
        "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free"])},
        "instant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instant"])},
        "network": {
          "infoMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make sure that the network is the same as the network of the platform you are withdrawing funds from or depositing funds to otherwise your assets may be lost."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network"])},
          "withdraw": {
            "warningMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please verify the network and the wallet address to which you want to withdraw the assets. Withdrawal to an incompatible network and/or to an incorrect wallet address will lead to loss of all assets."])}
          }
        },
        "networkFee": {
          "infoMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The fees displayed are estimated based on current network conditions. At the time of actual transaction execution, the fees may vary slightly due to factors such as network congestion, transaction size, and market fluctuations. We aim to provide the most accurate estimates, but these variations are beyond our control."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated network fee"])}
        },
        "notAllPaymentsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some deposit methods are not available at the moment."])},
        "notAllProvidersAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawals are not available at the moment."])},
        "notInstant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2-3 business days"])},
        "withdrawMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal method"])},
        "withdrawMethodPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose withdrawal method"])}
      },
      "sms": {
        "transaction": {
          "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request a voice call instead."])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the PIN we have just sent to your verified phone number."])},
          "input": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIN"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter PIN here"])}
          },
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Didn't receive the PIN?"])},
          "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend PIN"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm transaction"])},
          "toasts": {
            "sms": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A new PIN has been sent"])}
            },
            "voicecall": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voice call initiated"])}
            }
          },
          "validation": {
            "integer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIN must be numbers only"])},
            "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["PIN can have a maximum of ", _interpolate(_named("max")), " numbers"])},
            "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["PIN must have at least ", _interpolate(_named("min")), " numbers"])},
            "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIN is required"])}
          }
        }
      },
      "subaccountTransfer": {
        "buttons": {
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
          "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer"])}
        },
        "errors": {
          "INSUFFICIENT_FUNDS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to transfer funds - transfer amount exceeds available balance."])},
          "USER_MGMT_USER_IN_DERISK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to transfer funds - account in derisk."])}
        },
        "notMainSubaccount": {
          "text": {
            "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit is disabled for subaccounts."])},
            "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw is disabled for subaccounts."])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabled"])}
        },
        "success": {
          "successButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
          "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We have successfully transferred ", _interpolate(_named("amount")), " ", _interpolate(_named("symbol")), " from the ", _interpolate(_named("currency")), " \"", _interpolate(_named("subaccountFrom")), "\" to the \"", _interpolate(_named("subaccountTo")), "\"."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer successful!"])}
        },
        "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer"])},
        "transferFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer from"])},
        "transferMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer method"])},
        "transferMethodName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal transfer (instant)"])},
        "transferTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer to"])},
        "validation": {
          "decimal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This amount is invalid"])},
          "maxAmount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You cannot send more than your available funds ", _interpolate(_named("maxAmount")), " ", _interpolate(_named("symbol"))])},
          "minAmount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You need to send at least ", _interpolate(_named("minAmount")), " ", _interpolate(_named("symbol"))])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount is required"])}
        }
      },
      "twoFa": {
        "confirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorise now"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the 2FA code which was generated by your authenticator app."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two-factor authentication"])},
        "lostAccess": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.onetrading.com/hc/en-gb/articles/16164249199761-How-can-I-activate-or-reset-2FA-two-factor-authentication-"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lost access to your authenticator app?"])}
        },
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input 6 digit authentication code"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almost there"])},
        "validation": {
          "integer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two-factor authentication code must be numbers only"])},
          "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Two-factor authentication code can have a maximum of ", _interpolate(_named("max")), " numbers"])},
          "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Two-factor authentication code must have at least ", _interpolate(_named("min")), " numbers"])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two-factor authentication code is required"])}
        }
      },
      "unavailable": {
        "additionalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try again later or if the problem persists reach out to our support team. We apologise for any inconvenience."])}
      },
      "unavailableError": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whoops! Something went wrong! Please try again later."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry..."])}
      },
      "userBlocked": {
        "linkHref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.onetrading.com/hc/en-gb"])},
        "linkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["support"])},
        "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please contact ", _interpolate(_named("linkText")), "."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account is locked"])}
      },
      "verification": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
        "commonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to proceed, please complete registration."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account registration is incomplete"])}
      },
      "walletOnBitpanda": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("walletName")), " on Bitpanda"])},
      "walletOnBitpandaDefaultName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("symbol")), " wallet"])},
      "withdraw": {
        "confirmJurisdictionModal": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
          "depositMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To make a deposit, please confirm your country of residence."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm your Country of Residence"])},
          "withdrawMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To make a withdrawal, please confirm your country of residence."])}
        },
        "crypto": {
          "external": {
            "cancel": {
              "confirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have successfully cancelled your transaction."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal cancelled!"])}
            },
            "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
            "confirm": {
              "additionalText": [
                (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check"])},
                (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the transaction history"])},
                (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for more details."])},
                
              ],
              "confirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
              "linkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transaction history"])},
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your withdrawal has been sent to the blockchain and is awaiting confirmation."])},
              "textWithLink": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Check ", _interpolate(_named("linkText")), " for more details."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal successful!"])}
            },
            "confirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw now"])},
            "destinationTag": {
              "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("destinationTag"))])},
              "placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Input ", _interpolate(_named("destinationTag")), " (optional)"])},
              "validation": {
                "alphaNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This destination tag is invalid"])},
                "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination tag is required"])}
              }
            },
            "destinationTagType": {
              "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("destinationTag")), " type"])},
              "placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Input ", _interpolate(_named("destinationTag")), " type"])},
              "validation": {
                "alphaNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This destination tag type is invalid"])},
                "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination tag type is required"])}
              }
            },
            "doneButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
            "invalidLink": {
              "confirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This link is no longer valid."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whoops, something went wrong!"])}
            },
            "maintenance": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, we are currently undergoing maintenance…"])}
            },
            "noTransferMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No transfer methods available. Please contact customer support."])},
            "tabTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send to address"])},
            "transactionFeeNote": {
              "minimumAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum withdrawal amount (incl. fees)"])},
              "processingFee": {
                "infoMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A processing fee is applied to each transaction to cover the costs associated with executing and managing your transaction on our platform. This fee is charged by One Trading and is separate from any network fees. The processing fee ensures that we can continue to provide you with secure and efficient service. The processing fee is an estimate, and can vary slightly due to price fluctuations."])},
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing fee"])}
              },
              "totalExclFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total (excl. fee)"])},
              "totalInclFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
              "walletFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet withdrawal fees:"])}
            },
            "transferFrom": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer from"])},
              "value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("currency")), " wallet"])}
            },
            "unavailable": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, withdrawals are disabled at the moment."])}
            },
            "validation": {
              "decimal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This amount is invalid"])},
              "maxAmount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You cannot send more than your available funds ", _interpolate(_named("maxAmount")), " ", _interpolate(_named("symbol"))])},
              "minAmount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You need to send at least ", _interpolate(_named("minAmount")), " ", _interpolate(_named("symbol"))])},
              "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount is required"])}
            },
            "verification": {
              "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Before you can withdraw ", _interpolate(_named("currency")), " you will have to verify your account. Click “Verify now” to start the verification process."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to verify your account"])},
              "verifyButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify now"])}
            },
            "walletAddress": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input wallet address"])},
              "validation": {
                "alphaNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This wallet address is invalid"])},
                "isCryptoAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This wallet address is invalid"])},
                "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet address is required"])}
              }
            }
          }
        },
        "currencySelectorPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select currency"])},
        "fiat": {
          "external": {
            "buttons": {
              "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
              "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw now"])}
            },
            "cancel": {
              "confirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have successfully cancelled your transaction."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction cancelled!"])}
            },
            "confirm": {
              "additionalText": [
                (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check"])},
                (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the transaction history"])},
                (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to see the progress."])},
                
              ],
              "confirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
              "linkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the transaction history"])},
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The transaction is on its way."])},
              "textWithLink": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Check ", _interpolate(_named("linkText")), " to see the progress."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction successfully submitted!"])}
            },
            "invalidLink": {
              "confirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This link is no longer valid."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whoops, something went wrong!"])}
            },
            "payoutAccounts": {
              "accountLabels": {
                "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account name"])},
                "bank_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank"])},
                "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC/SWIFT"])},
                "bitpanda_blinc_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLINC ID"])},
                "blinc_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLINC ID"])},
                "blockchain_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet address"])},
                "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
                "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
                "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
                "payout_receiver_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
                "signet_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signet address"])}
              },
              "addAccount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Add ", _interpolate(_named("provider")), " account"])},
              "buttons": {
                "selectAnotherAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select or add another account"])}
              },
              "form": {
                "buttons": {
                  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
                  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])}
                },
                "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I hereby confirm that the recipient's account runs in my name."])},
                "fields": {
                  "account_holder_name": {
                    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account holder name"])},
                    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account holder name"])}
                  },
                  "address": {
                    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account holder address"])},
                    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account holder address"])}
                  },
                  "bank_name": {
                    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank name"])},
                    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank name"])}
                  },
                  "bic": {
                    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC/SWIFT"])},
                    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC/SWIFT"])}
                  },
                  "blinc_id": {
                    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLINC ID"])},
                    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLINC ID"])}
                  },
                  "blockchain_address": {
                    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet address"])},
                    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet address"])}
                  },
                  "country_code": {
                    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account holder country residence"])},
                    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account holder country residence"])}
                  },
                  "currency": {
                    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
                    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])}
                  },
                  "currency_code": {
                    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
                    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])}
                  },
                  "destination_tag": {
                    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination tag"])},
                    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination tag"])}
                  },
                  "destination_tag_type": {
                    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination tag type"])},
                    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination tag type"])}
                  },
                  "iban": {
                    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
                    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
                  },
                  "label": {
                    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account label"])},
                    "labelWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label for this account"])},
                    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account label"])}
                  },
                  "legal_name": {
                    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal name"])},
                    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal name"])}
                  },
                  "name": {
                    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom name (optional)"])},
                    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom name"])}
                  },
                  "papara_email": {
                    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
                    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
                  },
                  "payout_receiver_name": {
                    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient's name"])},
                    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient's name"])}
                  },
                  "signet_address": {
                    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signet address"])},
                    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signet address"])}
                  }
                },
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account details"])},
                "validation": {
                  "alphaNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is invalid. Only [A-Z] and [0-9] is allowed"])},
                  "bankNameValidator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is invalid. Only the characters [a-z, A-Z] are valid. No trailing or special characters are allowed"])},
                  "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid BIC/SWIFT"])},
                  "blinc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLINC ID should be 12 digits and start with 8"])},
                  "country_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country code should be two uppercase letters."])},
                  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The provided email is invalid"])},
                  "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid IBAN"])},
                  "ibanValidator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is invalid. Only [A-Z], [0-9] and blank spaces are allowed"])},
                  "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only letters, number and hyphens are allowed. Length 3-64."])},
                  "maxLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The name is too long"])},
                  "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field is required!"])},
                  "sameAs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field is required!"])},
                  "signet_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid Signet address"])}
                },
                "warnings": {
                  "blockchain_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make sure that the wallet address is correct. Otherwise, your assets will be lost."])}
                }
              },
              "limitNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the maximum amount of accounts. To add another account please remove one of the above by clicking on 'Edit'."])},
              "list": {
                "buttons": {
                  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
                  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use account"])},
                  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
                  "editDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])}
                },
                "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts"])},
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout account"])},
                "titleWithPrefix": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("prefix")), " account"])}
              },
              "remove": {
                "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to remove a payout account. This action cannot be undone.\n\nAre you sure you want to proceed?"])}
              },
              "states": {
                "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
                "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending..."])}
              },
              "type": {
                "blinc": {
                  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okay"])},
                  "successLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLINC ID"])},
                  "successText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have successfully added a BLINC account.\nThe account is now being processed and will be available shortly.\n\nPlease remember you need to link the following BLINC ID number to your BCB payout account. You must visit the BCB platform for this."])},
                  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully added!"])}
                }
              }
            },
            "remove": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to remove a linked payout account. This action cannot be undone. Are you sure you want to proceed?"])}
            },
            "transferFrom": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw from"])},
              "value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("currency")), " wallet"])}
            },
            "transferTo": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw to"])}
            },
            "validation": {
              "decimal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only numbers are allowed!"])},
              "maxAmount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You are about to exceed your available funds of ", _interpolate(_named("maxAmount"))])},
              "maxAmountDaily": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You are about to exceed the maximum daily amount ", _interpolate(_named("maxAmountDaily"))])},
              "minAmount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The entered amount must be at least ", _interpolate(_named("minAmount")), " ", _interpolate(_named("symbol"))])},
              "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field is required!"])}
            },
            "verification": {
              "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Before you can withdraw ", _interpolate(_named("currency")), " you will have to verify your account. Click “Verify now” to start the verification process."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to verify your account"])},
              "verifyButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify now"])}
            },
            "withdrawLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24h withdraw limit"])}
          }
        },
        "funding": {
          "external": {
            "buttons": {
              "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
              "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw now"])}
            },
            "cancel": {
              "confirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have successfully cancelled your transaction."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction cancelled!"])}
            },
            "confirm": {
              "linkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the transaction history"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The transaction is on its way. Check the transaction history to see the progress."])},
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The transaction is on its way."])},
              "textWithLink": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Check ", _interpolate(_named("linkText")), " to see the progress."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction successfully submitted!"])},
              "viewHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View history"])}
            },
            "invalidLink": {
              "confirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This link is no longer valid."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whoops, something went wrong!"])}
            }
          }
        },
        "restrictedJurisdictionModal": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
          "depositMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due to geographical restrictions, we are unable to support fiat transfers for your account at this time. However, you can still transfer crypto-assets from your One Trading account to an external wallet."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiat Transfers Unavailable in Your Jurisdiction"])},
          "withdrawMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due to geographical restrictions, we are unable to support fiat transfers for your account at this time. However, you can still transfer crypto-assets from your One Trading account to an external wallet."])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw"])},
        "validatePayment": {
          "additionalInformation": {
            "addressType": {
              "hosted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hosted"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address type"])},
              "unhosted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unhosted"])}
            },
            "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
            "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
            "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit information"])},
            "ownership": {
              "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I confirm that this address belongs to me"])},
              "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ownership"])}
            },
            "platform": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform name"])},
              "noMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No matching platform found"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter platform name"])},
              "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a platform"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform"])}
            },
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide the following information"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional wallet information"])}
          },
          "additionalInformationRequired": {
            "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due to regulations, we're required to collect additional information about this wallet address."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional information required"])}
          }
        }
      }
    },
    "transactionsHistory": {
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong. Please refresh the page and try again."])},
      "headers": {
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
        "bankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank name"])},
        "bankTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank transfer"])},
        "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])},
        "blincAccountID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLINC ID"])},
        "blincAccountLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLINC account"])},
        "blockchainId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction ID"])},
        "blockchainTX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
        "completedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed at"])},
        "depositCryptoExternal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposits"])},
        "depositCryptoExternalRefund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit Refund"])},
        "depositFiatExternal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deposit ", _interpolate(_named("provider"))])},
        "depositFiatExternalRefund": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("provider")), " deposit Refund"])},
        "depositFiatExternalRefundUnknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit refund"])},
        "depositInternal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer to One Trading"])},
        "depositWithdrawHistoryTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])},
        "destinationTag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination tag"])},
        "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One Trading"])},
        "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee"])},
        "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
        "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
        "initiated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initiated"])},
        "memo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memo"])},
        "memoID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memo ID"])},
        "memoType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memo type"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
        "netAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net amount"])},
        "payoutAccountLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account name"])},
        "pid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PID"])},
        "rebate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rebate"])},
        "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient"])},
        "refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund"])},
        "subaccountTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subaccount transfer"])},
        "transactionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction ID"])},
        "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet"])},
        "withdrawalCryptoExternal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawals"])},
        "withdrawalCryptoExternalRefund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal Refund"])},
        "withdrawalFiatExternal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Withdraw ", _interpolate(_named("provider"))])},
        "withdrawalFiatExternalRefund": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("provider")), " withdrawal Refund"])},
        "withdrawalFiatExternalRefundUnknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal refund"])},
        "withdrawalInternal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer to Bitpanda"])}
      },
      "statuses": {
        "CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])},
        "DONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
        "FAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
        "PENDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
        "REJECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])},
        "UNDER_REVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Under review"])},
        "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
        "finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
        "got_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
        "openInvitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New user invited"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
        "potential_fraud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual approval"])},
        "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])},
        "unconfirmed_transaction_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unconfirmed transaction"])},
        "under_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Under review"])},
        "waiting_for_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])}
      },
      "tabs": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all"])},
        "blinc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLINC"])},
        "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deposit"])},
        "funding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLINC/SEPA/SWIFT"])},
        "funds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLINC/SEPA"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pending"])},
        "transfers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfers"])},
        "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["withdraw"])}
      },
      "transactionType": {
        "depositCryptoExternal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deposit ", _interpolate(_named("provider"))])},
        "depositFiatExternal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deposit ", _interpolate(_named("provider"))])},
        "depositInternal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer from Bitpanda"])},
        "transfer": {
          "incoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming Subaccount transfer"])},
          "outgoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outgoing Subaccount transfer"])}
        },
        "withdrawalCryptoExternal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Withdraw ", _interpolate(_named("provider"))])},
        "withdrawalFiatExternal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Withdraw ", _interpolate(_named("provider"))])},
        "withdrawalInternal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer to Bitpanda"])}
      },
      "type": {
        "subaccountTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subaccount transfer"])}
      },
      "waitingForDepositFinish": {
        "cancel": {
          "failed": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelling failed"])}
          },
          "success": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit cancelled"])}
          }
        },
        "resendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish deposit"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for payment."])}
      },
      "waitingForEmail": {
        "cancel": {
          "failed": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong, please try again."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelling failed"])}
          },
          "success": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction cancelled!"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
          }
        },
        "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "resend": {
          "failed": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong, please try again."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending email failed"])}
          },
          "success": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your email inbox"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email sent"])}
          }
        },
        "resendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend email?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for email confirmation!"])}
      }
    },
    "userMenu": {
      "accountSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
      "affiliate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliate"])},
      "apiKeyManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API"])},
      "balances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balances"])},
      "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])},
      "blogUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://onetrading.com/blog"])},
      "closeAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close account"])},
      "closeAccountUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.onetrading.com/hc/en-gb/articles/16163804501521-How-can-I-delete-my-One-Trading-account-"])},
      "competitions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competitions"])},
      "exports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exports"])},
      "feeTiers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee levels"])},
      "fullyVerified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fully verified"])},
      "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
      "helpUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.onetrading.com/hc/en-gb"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
      "legalNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal notice"])},
      "legalNoticeUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://onetrading.com/en/legal-notice"])},
      "lightVerified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Light verified"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
      "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsletter"])},
      "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders"])},
      "otcTrading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTC Trading"])},
      "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotions"])},
      "payoutAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout accounts"])},
      "promotions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotions"])},
      "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
      "subaccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subaccounts"])},
      "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
      "tellAFriend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tell-a-Friend"])},
      "trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trades"])},
      "tradingAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts"])},
      "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])},
      "twoFa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable 2FA"])},
      "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify"])}
    }
  },
  "native": {
    "ios": {
      "NSFaceIDUsageDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For a quicker and easier log in."])},
      "appTracking": {
        "transparencyText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your data will be used to improve user experience by investigating general behaviour, crash reports, diagnostics and monitor logs to detect security issues."])}
      }
    }
  },
  "ui": {
    "capitalGainsTax": {
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are responsible for any taxes related to your activities on the One Trading platform.\n\nYou acknowledge that One Trading does not withhold taxes, and agree to release us of our obligation to withhold taxes where such option exists."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxes on One Trading"])}
    },
    "forbiddenJurisdictionModal": {
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We regret to inform you that due to regulatory restrictions we are currently unable to offer our services in your jurisdiction.\n\nClick \"Notify Me\" to be notified when our services become available in your jurisdiction."])},
      "notifyMeSuccess": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will be notified when our services become available in your jurisdiction."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opted in for notifications"])}
      },
      "primaryAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notify Me"])},
      "secondaryAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact support"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services Not Accessible in Your Jurisdiction"])}
    },
    "futuresAppropriatenessCooling": {
      "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Our records show that you have unsuccessfully attempted the appropriateness test three times. \n\nYou will have the option to retake the test in ", _interpolate(_named("number")), " days."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appropriateness Test Limit Reached"])}
    },
    "futuresAppropriatenessManualReviewModal": {
      "status-0": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit funds to your account"])},
        "message": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We're pleased to inform you that your application has been successfully reviewed, and you are now eligible to trade Futures with One Trading."])}
        }
      },
      "status-1": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to Spot Trading"])},
        "message": {
          "linkHref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.onetrading.com/hc/en-gb"])},
          "linkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact our support team"])},
          "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your application is under review. We will notify you of our decision within 7 days. If you have any questions in the meantime, please ", _interpolate(_named("linkText")), "."])}
        }
      },
      "status-2": {
        "canRetake-false": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
          "message": {
            "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We have reviewed your application for Futures trading and regret to inform you that you are currently ineligible to trade this product with One Trading. \n\nYou will have the option to retake the test in ", _interpolate(_named("number")), " days."])}
          }
        },
        "canRetake-true": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retake Test"])},
          "message": {
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have reviewed your application for Futures trading and regret to inform you that you are currently ineligible to trade this product with One Trading.\n\nYou may retake the Futures Appropriateness Test if you would still like to qualify for Futures trading."])}
          }
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Futures Trading Application Status"])}
    },
    "futuresAppropriatenessModal": {
      "actionPrimary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start test"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As part of the Client Acceptance Process, we are required to undertake an assessment of appropriateness of the products and services offered to you. The assessment of appropriateness means that we make a determination whether the investment service, product or transaction is appropriate for you. In doing so we will request from you information about your knowledge and experience regarding the product or service.\n\nThis will include questions about:\n\n  1. The types of service, transaction and financial instrument with which you are familiar;\n  2. The nature, volume, and frequency of your transactions in financial instruments and the period over which they have been carried out;\n  3. Your level of education, and profession or relevant former profession;\n  4. Your knowledge of the nature and risks of the relevant services and products;\n  5. The extent of your understanding of your own financial situation; and\n  6. Your need to obtain advice on the relevant services and products being offered.\n\nIf you are happy to proceed, please click the \"Start test\" button below."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appropriateness Test"])}
    },
    "futuresAppropriatenessRetakeModal": {
      "action": {
        "dismiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't Show me This Again"])},
        "retakeTest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retake Test"])}
      },
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are now eligible to retake the Futures Trading Appropriateness Test.\n\nThis is your opportunity to reassess your eligibility and see if you now qualify to trade Futures with One Trading."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Futures Appropriateness Test"])}
    },
    "futuresDisclaimerModal": {
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The products on this platform are offered by One Trading Exchange B.V. (“OTE”). OTE is a limited liability company regulated as an investment firm under the European Markets in Financial Instruments Directive[1] and the laws of the Netherlands, and supervised by the Dutch Authority for the Financial Markets (Autoriteit Financiële Markten).\nOTE provides services in relation to financial instruments, and specifically, derivatives on crypto assets. These instruments are cash-settled, which means their primary purpose is to hedge or speculate on the price of the underlying asset.\nThe products offered for trading by OTE are considered high-risk, and may be more volatile than the underlying asset, meaning they are likely to experience greater fluctuations in price and value. This means that when investing in these assets, you should be prepared to absorb significant losses, including a total loss of all assets or funds invested.\nOne Trading Exchange B.V does not provide any advice or guidance in relation to the trading or investment activities in relation to the services it provides, or the financial instruments offered for trading through its systems. One Trading Exchange B.V. therefore does not recommend that any specific financial instrument is purchased, sold or held. You should ensure you have conducted your own research and review, and have all the necessary information to be able to make an informed decision before deciding to trade with One Trading Exchange B.V.. You may want to consult with a professional financial advisor.\nPlease confirm you have read and understood this notice by clicking on the box below.\n\n[1] (Directive 2014/65/EU of the European Parliament and of the Council of 15 May 2014 on markets in financial instruments and amending Directive 2002/92/EC and Directive 2011/61/EU (recast) “MiFID II”)"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One Trading - IMPORTANT INFORMATION: Derivatives Trading"])}
    },
    "ineligibleToTrade": {
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact support"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due to current requirements, including legal and regulatory obligations, we are not currently able to provide these services to you. We will notify you in the future should this position change."])}
    },
    "priceWarningModal": {
      "agreeButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place order"])},
      "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't show this again"])},
      "messageLimitBuy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This order might lead to an immediate fill because the limit price you specified is higher than or equal to the best price available in the order book. Do you want to proceed?"])},
      "messageLimitSell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This order might lead to an immediate fill because the limit price you specified is lower than or equal to the best price available in the order book. Do you want to proceed?"])},
      "messageStopBuy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This order might lead to an immediate fill when the stop price is triggered as the limit price is higher than the stop price. Do you want to proceed?"])},
      "messageStopSell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This order might lead to an immediate fill when the stop price is triggered as the limit price is lower than the stop price. Do you want to proceed?"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm"])}
    },
    "retailAmlModal": {
      "goToForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Information"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regulations require us to ensure our customer data is up to date. Please take a moment to answer the following personal trading information questions."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update your Personal Trading Information"])}
    },
    "riskWarningModal": {
      "agreeButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
      "message1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One Trading is an advanced digital asset platform for experienced traders, professionals and institutions."])},
      "message": {
        "linkHref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["http://www.onetrading.com/EN/terms-and-conditions"])},
        "linkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One Trading Terms and Conditions"])},
        "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["By using One Trading you herewith confirm to have trading experience and a good understanding of different order types.  Further, you accept that trading is subjected to high market risk and may lead to loss of your assets. You are highly advised to invest cautiously. You trade on One Trading entirely at your own risk (for further information, please see the ", _interpolate(_named("linkText")), ")."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disclaimer One Trading"])}
    },
    "settlementDelay": {
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expect delays in fiat settlement due to upcoming bank holidays."])}
    },
    "termsAndConditions": {
      "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree"])},
      "disclaimer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We have made certain updates to our T&Cs, particularly due to upcoming regulatory changes. Please read and familiarize yourself with the ", _interpolate(_named("linkTermsAndConditions")), " (note that the link also includes a comparison of versions showing all changes made). The updated General Terms and Conditions of One Trading are effective from ", _interpolate(_named("termsEffectiveDate")), ".\n \nIf you disagree with these changes, you have the right to terminate your legal relationship with us under the T&C until that date by contacting our ", _interpolate(_named("linkCustomerCare")), "."])},
      "disclaimerLinks": {
        "linkSupport": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.onetrading.com/hc/en-gb"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Care Team"])}
        },
        "linkTermsAndConditions": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["http://onetrading.com/terms-and-conditions-updates"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new General Terms and Conditions of One Trading"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update to T&Cs"])}
    },
    "ukSelfCertification": {
      "description": {
        "after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a crypto-asset service provider registered in the EU, legislation in the UK limits our ability to provide our services to certain customers in the UK. As you are resident in the UK, we kindly request that you complete the Self-Certification form. \nFunctionality (order cancellations, withdrawals, transfers) on the One Trading platform will be restricted for users who have not self-certified as an “investment professional”."])},
        "before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a crypto-asset service provider registered in the EU, legislation in the UK limits our ability to provide our services to certain customers in the UK. As you are resident in the UK, we kindly request that you complete the Self-Certification form. \nFrom October 8th, functionality (order cancellations, withdrawals, transfers) on the One Trading platform will be restricted for users who have not self-certified as an “investment professional”."])}
      },
      "primaryAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue to the form"])},
      "secondaryAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remind me later"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete self-certification form"])}
    },
    "ukSelfCertificationNotEligible": {
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you. You have self-certified as a person not eligible for services. You are not able to access full functionality of the One Trading platform. To cancel an order, make a withdrawal or transfer starting, please contact customer support."])},
      "primaryAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact support"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self-certification form"])}
    }
  },
  "views": {
    "404": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The page you wanted to visit was not found"])},
      "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 - Not found"])}
    },
    "loginError": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One Trading"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong. To return to One Trading, please do one of the following:\n\n- If you are using the One Trading app, please close the in-app browser window to return to the app\n\n- If you are on web, then please click the link below to return to "])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are sorry!"])}
    },
    "maintenance": {
      "dialog": {
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact support"])},
        "textAddition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apologies for any inconvenience, we will be back soon!"])},
        "textMain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The One Trading platform is undergoing an upgrade to improve trade transaction speeds (from ", _interpolate(_named("start")), " to ", _interpolate(_named("end")), ", subject to change)."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade in progress"])}
      }
    }
  }
}